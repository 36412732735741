<template>
  <div class="item">
     <span class="jumpCouponBtn" @click="jumpGetCoupon">去领券中心 <i class="el-icon-arrow-right"></i></span>
    <TitleHeader msg="优惠劵"></TitleHeader>
    <el-row class="elBox">
        <!-- <el-col>
            <ul>
              <li v-for="(item,index) in arr" :key="index" :class="currenIndex === index ?'active':''"  @click="changIndex(index)">{{item}}</li>
            </ul>
        </el-col> -->
        <el-col :gutter="20">
            <CouponItem v-for='(item,index) in couponlist' :key="index" :span="8" :couponItem="item"></CouponItem>
        </el-col>
    </el-row>
  </div>
</template>

<script>
import {LookCouponList} from 'api/member.js';
const TitleHeader = ()=>import("components/common/TitleHeader.vue");
const CouponItem = ()=>import("components/common/CouponItem.vue");
  export default {
    name:"Coupon",
    data() {
      return {
         total: 0,
        current: 1, //当前页
        size: 12, //每页数量
        arr:["已失效0","可使用1","已使用2"],
        currenIndex:0,
        couponName:'',
        couponlist:''
    }
    },
    methods:{
        jumpGetCoupon(){
       this.$router.push({ name: "GetCoupon" });
    },
     changIndex(index){
       this.currenIndex = index
     },

    handleCurrentChange(val) {
      this.current = val;
      this.size=12; //每页数量
       this.lookCouponList();
    },
    //  获取查看我的优惠券列表
    lookCouponList(){
        
        LookCouponList().then(data=>{
          this.couponlist=data.data.data
           this.total = Number(data.data.data.total);
        })
    }
  },
   created(){
     this.lookCouponList()
    },
     components:{
       TitleHeader,
       CouponItem
    }
  }
</script>
<style lang="less">
@import "~style/theme.less";
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: @theme;
  color: #fff;
}
/* 上一页，下一页样式 */
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  background: #fff;
  color: #666;
  padding: 2px;
  box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li {
  background: #fff;
  color: #666;
  padding: px;
  box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
  color: @theme;
  font-size: 14px;
}
</style>
<style lang="less" scoped>
@import '~style/index.less';
.pageBox {
  float: left;
  width: 100%;
  .el-pagination {
    padding-top: 40px;
    text-align: center;
    float: right;
  }
}
.item {
   position: relative;
  .jumpCouponBtn{
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 10px;
    line-height: 22px;
    display: inline-block;
    background-color:  @theme;;
    border-color:  @theme;;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    padding: 3px 20px;
    z-index: 999;
  }
  .elBox{
    margin-top:20px
  }
      ul {
        border-bottom: 1px solid #eee;
        height:30px;
        margin-bottom:20px;
        li {
        float: left;
        padding-left: 20px;
      }
      }
      .active {
        color: red;
      }
}
 
</style>
